<template>
  <a-modal
    v-model="visible"
    width="80%"
    :maskClosable="false"
    :title="title"
    :confirmLoading="loading"
    :ok-text="okText"
    cancel-text="取消"
    :ok-button-props="{ props: { disabled: selectedRowKeys.length == 0 } }"
    @ok="confirm"
  >
    <div class="data-title flex-box justify-between">
      <div>申购单编号：{{ detail.subscribe_num }}</div>
      <a-button type="primary" @click="add" v-if="is_warehousing">新增商品</a-button>
    </div>
    <a-table
      ref="dataTable"
      :columns="columns"
      :row-key="(record) => record.key"
      :data-source="detail.list"
      :row-selection="rowSelection"
      :pagination="pagination"
      :scroll="{ x: 1400 }"
      @change="handleTableChange"
    >
      <template v-for="col in colList" :slot="col.dataIndex" slot-scope="text, record">
        <div :key="col.dataIndex">
          <component
            v-if="record.editable || getEditable(col, record)"
            :is="col.component"
            :style="col.style"
            :max="getEditable(col, record) ? record.max_num : 999999"
            v-model="record[col.key]"
            v-bind="{ ...col.props }"
            v-on="encapsulationListeners(col.listeners, record)"
          >
          </component>
          <template v-else>
            <div v-if="col.openData">
              <open-data type="userName" :openid="record[col.key]" v-if="record[col.key]" />
              <span v-else>-</span>
            </div>
            <div v-else-if="col.file">
              <div v-if="record[col.key] && record[col.key].length > 0">
                <div v-for="file in record[col.key]" :key="file.url">
                  <a :href="file.url">{{ file.name }}</a>
                </div>
              </div>
              <span v-else>-</span>
            </div>
            <div v-else-if="col.custom && typeof col.custom == 'function'">{{ col.custom(record) }}</div>
            <div v-else>{{ record[col.key] || "-" }}</div>
          </template>
        </div>
      </template>
      <template slot="action" slot-scope="text, record, index">
        <div class="editable-row-operations">
          <div class="row-btn" v-if="record.editable">
            <a class="txt-btn" @click="save(record)">保存</a>
            <a class="txt-btn danger" @click.stop="delRow(record, index)">
              删除
            </a>
          </div>
          <div class="row-btn" @mousedown.stop @mouseup.stop v-else>
            <a class="txt-btn" :class="record.status >= 2 ? 'grey' : ''" @click.stop="editRow(record, index)">
              编辑
            </a>
            <a class="txt-btn danger" :class="record.status >= 2 ? 'grey' : ''" @click.stop="delRow(record, index)">
              删除
            </a>
          </div>
        </div>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { fixedAssetsOptions } from "../../../common/asset/type";
import {
  clone,
  randomString,
  getKeyTitle,
  getSuffix,
  isEmpty,
  stringConvertTimestamp,
  debounce,
} from "../../../common/js/tool";

export default {
  name: "OrderModal",
  model: {
    event: "change",
  },
  props: {
    value: {
      type: Boolean,
      value: false,
    },
    type: {
      type: Number,
      default: 1, // 1: 入库 2: 出库
    },
    info: Object,
    form: Object,
  },
  data() {
    this.lastFetchId = 0;
    this.goodsSearch = debounce(this.goodsSearch, 300);
    return {
      loading: false,
      detail: {
        subscribe_num: 1,
        list: [],
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
      },
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      getKeyTitle,
      getSuffix,

      fetching: false,
      goodsOptions: [],
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
    // 入库
    is_warehousing() {
      return this.type == 1;
    },
    // 出库
    is_outbound() {
      return this.type == 2;
    },
    title() {
      return this.is_warehousing ? "选择入库商品" : "选择出库商品";
    },
    columns() {
      let columns = [
        { title: "序列号", dataIndex: "rowIndex", width: 80 },
        { title: "商品单价", dataIndex: "unit_price", scopedSlots: { customRender: "unit_price" } },
        { title: "商品金额", dataIndex: "price", scopedSlots: { customRender: "price" } },
        {
          title: "交货人",
          dataIndex: "delivered_by",
          scopedSlots: { customRender: "delivered_by" },
        },
        { title: "备注", dataIndex: "notes", scopedSlots: { customRender: "notes" } },
        {
          title: "附件",
          dataIndex: "annex",
          scopedSlots: { customRender: "annex" },
        },
      ];
      if (this.is_warehousing) {
        // 入库
        columns.splice(4, 0, {
          title: "是否固定资产",
          dataIndex: "fixed_id",
          width: 200,
          scopedSlots: { customRender: "fixed_id" },
        });
        columns.splice(
          1,
          0,
          { title: "商品名称", dataIndex: "subscribe_goods", scopedSlots: { customRender: "subscribe_goods" } },
          { title: "商品数量", dataIndex: "num", scopedSlots: { customRender: "num" } }
        );
        columns.push(
          { title: "入库状态", dataIndex: "statusText" },
          { title: "操作", dataIndex: "action", width: 120, scopedSlots: { customRender: "action" } }
        );
      } else if (this.is_outbound) {
        // 出库
        columns.splice(4, 0, {
          title: "固定资产",
          dataIndex: "fixed_id",
          scopedSlots: { customRender: "fixed_id" },
        });
        columns.splice(
          1,
          0,
          { title: "商品名称", dataIndex: "subscribe_goods", scopedSlots: { customRender: "subscribe_goods" } },
          { title: "出库数量", dataIndex: "residue_num", scopedSlots: { customRender: "residue_num" } }
        );
        columns.push({ title: "出库状态", dataIndex: "statusText" });
      }
      return columns;
    },
    colList() {
      let colList = [
        {
          dataIndex: "unit_price",
          key: "unit_price",
          component: "a-input-number",
          props: {
            placeholder: "输入单价",
            allowClear: true,
            min: 0,
            step: 0.01,
          },
          listeners: {
            change: this.updateTotalAmount,
          },
          style: { width: "120px" },
        },
        {
          dataIndex: "price",
          key: "price",
          component: "a-input-number",
          props: {
            placeholder: "输入合计金额",
            allowClear: true,
            min: 0,
            step: 0.01,
            disabled: true,
          },
          style: { width: "120px" },
        },
        {
          dataIndex: "delivered_by",
          key: "delivered_by",
          component: "select-book",
          props: {
            type: "user",
          },
          openData: true,
          style: { width: "140px" },
        },
        {
          dataIndex: "fixed_id",
          key: "fixed_id",
          component: "a-select",
          props: {
            options: fixedAssetsOptions,
            placeholder: "选择资产类型",
            allowClear: true,
          },
          listeners: {
            change: this.assetsChange,
          },
          style: { width: "180px" },
          custom: (record) => {
            return this.is_outbound
              ? record.fixed?.asset_code
              : getKeyTitle(fixedAssetsOptions, record.fixed_id, "value", "label") || "-";
          },
        },
        {
          dataIndex: "notes",
          key: "notes",
          component: "a-input",
          props: {
            placeholder: "填写备注",
          },
          style: { width: "120px" },
        },
        {
          dataIndex: "annex",
          key: "annex",
          component: "form-upload",
          props: {
            listType: "text",
            limit: 3,
            saveFileName: true,
            size: 1024 * 1024 * 1,
            customAccept: ".excel,.word,.pdf,.xlsx",
          },
          listeners: {
            change(e) {
              console.log(e);
            },
          },
          style: { width: "140px" },
          file: true,
        },
      ];
      if (this.is_warehousing) {
        // 入库
        colList.splice(
          0,
          0,
          {
            dataIndex: "subscribe_goods",
            key: "subscribe_goods",
            component: "a-auto-complete",
            props: {
              placeholder: "输入商品名称查询",
              allowClear: true,
              dataSource: this.goodsOptions,
              filterOption: false,
            },
            listeners: {
              search: this.goodsSearch,
            },
            style: { width: "120px" },
          },
          {
            dataIndex: "num",
            key: "num",
            component: "a-input-number",
            props: {
              placeholder: "输入商品数量",
              allowClear: true,
              min: 1,
              step: 1,
            },
            listeners: {
              change: this.updateTotalAmount,
            },
            style: { width: "120px" },
          }
        );
        colList.push({
          dataIndex: "statusText",
          key: "statusText",
          component: "a-input",
          props: {
            placeholder: "入库状态",
            allowClear: true,
          },
          style: { width: "120px" },
        });
      } else if (this.is_outbound) {
        // 出库
        colList.splice(
          0,
          0,
          {
            dataIndex: "subscribe_goods",
            key: "subscribe_goods",
            component: "a-input",
            props: {
              placeholder: "商品名称",
              allowClear: true,
            },
            style: { width: "120px" },
          },
          {
            dataIndex: "residue_num",
            key: "residue_num",
            component: "a-input-number",
            props: {
              placeholder: "输入出库数量",
              allowClear: true,
              min: 1,
              step: 1,
            },
            listeners: {
              change: this.updateTotalAmount,
            },
            style: { width: "120px" },
          }
        );
        colList.push({
          dataIndex: "statusText",
          key: "statusText",
          component: "a-input",
          props: {
            placeholder: "出库状态",
            allowClear: true,
          },
          style: { width: "120px" },
        });
      }
      return colList;
    },
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps: this.getCheckboxProps,
      };
    },
    okText() {
      return this.is_warehousing ? "确认入库" : "确认出库";
    },
  },
  watch: {
    info() {
      this.setInfo();
    },
  },
  created() {
    this.setInfo();
  },
  methods: {
    setInfo() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      if (this.info) {
        let info = clone(this.info);
        let { current, pageSize } = this.pagination;
        info.list?.forEach((item, index) => {
          item.rowIndex = (current - 1) * pageSize + index + 1;
          if (item.annex) {
            item.annex = JSON.parse(item.annex);
            item.annex.forEach((file) => {
              file.name = file.name || file.fileName;
            });
          }
          if (!item.key) {
            item.key = item.id;
          }
          let { ex_warehouse_num = 0, num = 0, status } = item;
          if (this.is_warehousing) {
            // 入库状态
            item.warehouse_status = status;
            item.statusText = status == 1 ? "未入库" : "已入库";
            // 入库，默认勾选全部（已入库除外）
            if (status == 1) {
              this.selectedRowKeys.push(item.key);
              this.selectedRows.push(item);
            }
          } else if (this.is_outbound) {
            if (status == 1) {
              // 未入库
              item.statusText = "未入库";
              item.outbound_status = 1;
            } else {
              // 出库状态
              if (ex_warehouse_num <= 0) {
                item.statusText = "未出库";
                item.outbound_status = 2;
                item.residue_num = num;
                item.max_num = num;
              } else if (ex_warehouse_num < num) {
                let residue_num = num - ex_warehouse_num; // 剩余数量
                item.statusText = `已出${ex_warehouse_num},未出${residue_num}`;
                item.outbound_status = 3;
                item.residue_num = residue_num;
                item.max_num = residue_num;
              } else {
                item.statusText = "已出库";
                item.outbound_status = 4;
                item.residue_num = 0;
                item.max_num = 0;
              }
            }
            // 出库，默认勾选全部（未入库与已出库除外）
            if ([2, 3].includes(item.outbound_status)) {
              this.selectedRowKeys.push(item.key);
              this.selectedRows.push(item);
            }
          }
        });
        this.detail = info;
      }
    },
    onSelectChange(keys, rows) {
      this.selectedRowKeys = keys;
      this.selectedRows = rows;
    },
    getCheckboxProps(record) {
      return {
        props: {
          disabled: record.warehouse_status > 1 || [1, 4].includes(record.outbound_status), // 1.入库：已入库过的无法再勾选 2.出库：未入库与已出库过的无法再勾选
        },
      };
    },
    encapsulationListeners(listeners, record) {
      let res = {};
      for (let key in listeners) {
        let handler = listeners[key];
        res[key] = ($event) => {
          handler.call(this, $event, record);
        };
      }
      return res;
    },
    handleTableChange(pagination) {
      let pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;
    },
    getEditable(col, record) {
      return col.dataIndex === "residue_num" && [2, 3].includes(record.outbound_status) && !record.fixed_id;
    },
    // 更新合计金额
    updateTotalAmount(val, record) {
      if (!isEmpty(record.num) && !isEmpty(record.unit_price)) {
        this.$set(record, "price", record.num * record.unit_price);
      } else {
        this.$set(record, "price", 0);
      }
    },
    // 资产类型改变
    assetsChange(val, record) {
      record.fixed_assets_type = val;
      if (val) {
        record.is_fixed_assets = 1;
      } else {
        record.is_fixed_assets = 0;
      }
    },
    // 新增
    add() {
      let list = this.detail.list;
      let item = {
        rowIndex: list.length + 1,
        key: randomString(16),
        editable: true,
        status: 1,
        statusText: "未入库",
      };
      list.push(item);
      this.selectedRowKeys.push(item.key);
      this.selectedRows.push(item);
    },
    // 编辑
    editRow(record) {
      if (record.status == 2) {
        return;
      }
      this.$set(record, "editable", true);
    },
    // 保存
    save(record) {
      if (isEmpty(record.subscribe_goods)) {
        this.$message.warning("请输入商品名称");
        return;
      }
      if (isEmpty(record.num)) {
        this.$message.warning("请输入商品数量");
        return;
      }
      record.editable = false;
    },
    // 删除
    delRow(record, index) {
      if (record.status == 2) {
        return;
      }
      this.$confirm({
        title: "提示",
        content: `确定删除此商品吗？`,
        onOk: () => {
          let list = this.detail.list;
          let target = list.splice(index, 1)[0];
          for (let i = index; i < list.length; i++) {
            list[i].rowIndex--;
          }
          // 选中的被删除，进行同步
          let kIndex = this.selectedRowKeys.findIndex((key) => key == target.key);
          if (kIndex >= 0) {
            this.selectedRowKeys.splice(kIndex, 1);
            this.selectedRows.splice(kIndex, 1);
          }
        },
      });
    },
    // 确认
    confirm() {
      if (this.loading) {
        return;
      }
      if (this.selectedRowKeys.length == 0) {
        this.$message.warning("请至少选择一个商品！");
        return;
      }
      this.loading = true;
      let list = this.detail.list;
      let info = this.info;
      let form = clone(this.form);
      if (this.is_warehousing) {
        let target = list?.find((item) => item.editable);
        if (target) {
          this.$message.warning(`请保存序列号为${target.rowIndex}的商品`);
          this.loading = false;
          return;
        }
        // 入库
        let commidty_info = {
          add: [],
          update: [],
          delete: [],
        };
        let oldList = this.info?.list ? clone(this.info.list) : [];
        list.forEach((item) => {
          if (item.annex) {
            item.annex = JSON.stringify(item.annex);
          }

          if (this.selectedRowKeys.includes(item.key)) {
            // 入库标识
            item.selected = true;
          }
          if (item.id) {
            if (oldList.length > 0) {
              let index = oldList.findIndex((val) => val.id == item.id);
              oldList.splice(index, 1);
              commidty_info.update.push(item);
            }
          } else {
            commidty_info.add.push(item);
          }
        });
        // 入库的无法操作，不用更新
        commidty_info.update = commidty_info.update.filter((item) => item.status == 1);
        // 剩下的为被删除
        commidty_info.delete = oldList;
        this.$axios({
          url: "/admin/asset-warehouse/subscibe-goods",
          method: "POST",
          data: {
            subscribe_num: info.subscribe_num,
            content: commidty_info,
            category: form.category,
            warehouse_date: stringConvertTimestamp(form.warehouse_date),
          },
        })
          .then((res) => {
            if (res.error == 0) {
              this.$message.success("入库成功！");
              this.$emit("success");
              this.visible = false;
            } else {
              this.$message.error(res.msg);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.is_outbound) {
        // 出库
        let params = {
          subscribe_num: info.subscribe_num,
          list: this.selectedRows.map((item) => {
            return {
              ...item,
              director: item.tangkou_director,
              ex_warehouse_num: item.residue_num,
            };
          }),
        };
        this.handleParams(params, form, [
          { key: "tangkou_id" },
          { key: "director" },
          { key: "category" },
          { key: "ex_warehouse_date", timestamp: true },
        ]);
        if (form.category == 4) {
          // 借用出库
          this.handleParams(params, form, [
            { key: "borrow_date", timestamp: true },
            { key: "repay_date", timestamp: true },
          ]);
        }
        this.$axios({
          url: "/admin/asset-ex-warehouse/subscibe-goods",
          method: "POST",
          data: params,
        })
          .then((res) => {
            if (res.error == 0) {
              this.$message.success("出库成功！");
              this.$emit("success");
              this.visible = false;
            } else {
              this.$message.error(res.msg);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    handleParams(params, form, handleList) {
      handleList?.forEach((item) => {
        let key = item.key;
        let value = form[key];
        if (item.timestamp) {
          params[key] = stringConvertTimestamp(value);
        } else {
          params[key] = value;
        }
      });
      return params;
    },
    goodsSearch(keyword) {
      if (isEmpty(keyword)) {
        this.goodsOptions = [];
      } else {
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.goodsOptions = [];
        this.fetching = true;
        this.$axios({
          url: "/admin/asset-goods",
          method: "GET",
          params: {
            sort: "-id",
            expand: "goodsUnit",
            page: 1,
            pageSize: 100,
            "filter[name][like]": keyword,
          },
        })
          .then((res) => {
            if (fetchId !== this.lastFetchId) {
              // for fetch callback order
              return;
            }
            this.goodsOptions = res.data.map((item) => {
              return {
                ...item,
                value: item.name,
                text: item.name,
              };
            });
          })
          .finally(() => {
            this.fetching = false;
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.data-title {
  margin-bottom: 12px;
}
</style>
