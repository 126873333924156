// 入库类别
export const warehousingOptions = [
  {
    label: "采购入库",
    value: 1,
  },
  {
    label: "盘盈入库",
    value: 2,
  },
  {
    label: "结缘赠送",
    value: 3,
  },
  {
    label: "借用入库",
    value: 4,
  },
];

// 出库类别
export const outboundOptions = [
  {
    label: "领料出库",
    value: 1,
  },
  {
    label: "盘亏出库",
    value: 2,
  },
  {
    label: "消耗出库",
    value: 3,
  },
  {
    label: "借用出库",
    value: 4,
  },
];

// 固定资产
export const fixedAssetsOptions = [
  {
    label: "家具(木制)等-----[A]",
    value: 1,
  },
  {
    label: "电子电器---------[B]",
    value: 2,
  },
  {
    label: "专用设备---------[C]",
    value: 3,
  },
  {
    label: "交通工具---------[D]",
    value: 4,
  },
  {
    label: "佛教用品---------[E]",
    value: 5,
  },
  {
    label: "文物(瓷器字画)---[F]",
    value: 6,
  },
  {
    label: "其它--------------[G]",
    value: 7,
  },
];

// 价格条件
export const priceConditionOptions = [
  {
    label: "价格等于=",
    value: 1,
  },
  {
    label: "价格大于>",
    value: 2,
  },
  {
    label: "价格小于<",
    value: 3,
  },
];

// 固定资产状态
export const fixedStatusOptions = [
  {
    label: "库内",
    value: 1,
  },
  {
    label: "库外",
    value: 2,
  },
  {
    label: "报废",
    value: 3,
  },
];

// 固定资产来源
export const fixedSourceOptions = [
  {
    label: "采购入库",
    value: 1,
  },
  {
    label: "捐赠入库",
    value: 2,
  },
  {
    label: "发掘入库",
    value: 3,
  },
  {
    label: "其他入库",
    value: 4,
  },
  {
    label: "互赠礼品",
    value: 5,
  },
];

// 盘点状态
export const inventoryStatusOptions = [
  {
    label: "不存在",
    value: 0,
  },
  {
    label: "存在",
    value: 1,
  },
];
